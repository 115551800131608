import React, { Component } from "react";
import { Content, Row, Col, DataTable } from "adminlte-2-react";
import axios from "axios";
import API from "./../../constants";
import ApiRequest from "./../../ApiRequest";
import LoadingSpinner from "./../../components/LoadingSpinner";
import "./User.css";
import Pagination from "react-js-pagination";
import LoadingAjax from "../../components/Loading";
const user_url = API.apiEndpoint + API.adminUser;
const postssignStoreMobile_url = API.apiEndpoint + API.uploadAssignStoreMobile;
const getInvalidMobileAttempt_url = API.apiEndpoint + API.invalidMobileAttempt;

export default class UserListing extends Component {
  state = {
    user_list: [],
    loading: true,
    error: false,
    message: "",
    limit: 10,
    pagination: {},
    activePage: 1,
    search_clear: true,
    search_select_value: "",
    search_input_value: "",
  };

  getData = async (
    pageNumber = 1,
    limit = 10,
    filter = false,
    key = "",
    value = ""
  ) => {
    let options = {
      url:
        user_url +
        "?storeListingPage=1&page=" +
        pageNumber +
        "&limit=" +
        limit +
        "&filter=" +
        filter +
        "&key=" +
        key +
        "&value=" +
        value,
    };

    this.setState({ loading: true });
    let api_response = await ApiRequest.get(options);
    if (api_response.error) {
      this.setState({
        loading: false,
        error: true,
        message: api_response.message,
      });
    } else {
      this.setState({
        user_list: api_response.data.results,
        loading: false,
        error: false,
        pagination: api_response.data.pagination,
        message: api_response.message,
      });
    }
  };

  async componentDidMount() {
    await this.getData();
  }

  handlePageChange = async (pageNumber) => {
    await this.getData(
      pageNumber,
      this.state.limit,
      this.state.filter,
      this.state.search_select_value,
      this.state.search_input_value
    );
  };

  handleLimitChange = async (event) => {
    this.setState({ limit: event.target.value });
    await this.getData(1, event.target.value);
  };
  handleSearchChange = async (event) => {
    this.setState({
      search_clear: false,
      search_select_value: event.target.value,
      filter: true,
    });
    await this.getData(
      1,
      this.state.limit,
      true,
      event.target.value,
      this.state.search_input_value
    );
  };

  handleSearchValueChange = async (event) => {
    this.setState({
      search_clear: false,
      search_input_value: event.target.value,
      filter: true,
    });
    await this.getData(
      1,
      this.state.limit,
      true,
      this.state.search_select_value,
      event.target.value
    );
  };

  handleSearchClear = async () => {
    this.setState({
      search_clear: true,
      search_input_value: "",
      search_select_value: "",
      filter: false,
    });
    await this.getData(1, this.state.limit, false);
  };

  onClickHandler = async (event) => {
    const data = new FormData();
    data.append("file", event.target.files[0]);
    this.setState({ loading: true });
    try {
      let rsp = await axios.post(postssignStoreMobile_url, data, {
        headers: { "X-Access-Token": localStorage.getItem("user_token") },
      });
      if (rsp.status === 200) {
        this.setState({
          success: true,
          loading: false,
          success_message: "Business Admin CSV uploaded",
        });
        /*setTimeout(
         function () {
         this.setState({ success: false });
         }.bind(this),
         5000
         );*/
        //this.openModal(rsp.data.data);
      } else {
        let message = rsp.data.message;
        this.setState({
          error: true,
          loading: false,
          error_message: message.param_missing
              ? message.param_missing + " is required"
              : message,
        });
      }
    } catch (e) {
      let message = "Error Uploading file";
      this.setState({
        error: true,
        loading: false,
        message: message,
      });
    }
  };
  
  onInvalidAttemptHandler = async (event) => {
    this.setState({ loading: true });
    try {
      let rsp = await axios.get(getInvalidMobileAttempt_url, {
        headers: { "X-Access-Token": localStorage.getItem("user_token") },
      });

      if (rsp.status === 200) {
        this.setState({
          success: true,
          loading: false,
          success_message: "Cleared OTP issue",
        });
      } else {
        let message = rsp.data.message;
        this.setState({
          error: true,
          loading: false,
          error_message: message.param_missing
              ? message.param_missing + " is required"
              : message,
        });
      }
    } catch (e) {
      let message = "Authentication issue";
      this.setState({
        error: true,
        loading: false,
        message: message,
      });
    }
  };

  rescue_value(value) {
    return value === undefined ? "-" : value;
  }

  rescue_status(value) {
    return value === 1 ? "Active" : "Inactive";
  }

  render() {
    const {
      loading,
      error,
      message,
      success,
      success_message,
      user_list,
      pagination,
      limit,
      //search_select_value,
      //search_input_value
    } = this.state;
    return (
      <Content title="Admin User" browserTitle="Admin User">
        <LoadingAjax />
        <Row>
          {error && (
            <div
              class="alert alert-danger alert-dismissible"
              style={{ marginRight: "15px", marginLeft: "15px" }}
            >
              <button
                type="button"
                class="close"
                data-dismiss="alert"
                aria-hidden="true"
              >
                ×
              </button>
              {message}
            </div>
          )}
          {success && (
          <div
          class="alert alert-success alert-dismissible"
            style={{ marginRight: "15px", marginLeft: "15px" }}
          >
          <button
            type="button"
          class="close"
            data-dismiss="alert"
            aria-hidden="true"
                >
                        ×
          </button>
          {success_message}
          </div>
          )}

          <Col xs={12}>
            <div className="box box-primary">
              <div className="box-header with-border box-table-cus">
                <h3 className="box-title">Admin User</h3>
                <div className="box-tools">
                  <div>
                    {/*ApiRequest.can_access("admin-user", "can_add_single") && (
                      <a
                        class="btn btn-primary btn-xs mr-5"
                        href={"/admin_user_form/"}
                      >
                        {" "}
                        Add New
                        {"    "}
                      </a>
                    )*/}
                          <button
                      type="button"
                      className="btn btn-sm btn-primary"
                      onClick={this.onInvalidAttemptHandler}
                    >
                      {"Clear OTP Issue"}
                    </button>
                    {" "}
                    <a
                      className="btn btn-sm btn-primary mr-5"
                      href="bulk_admin_mobile.csv"
                          >
                          Bulk Station User Mobile Sample CSV
                      </a>
                      <input
                      id="uploadList"
                      type="file"
                      name="file"
                      ref={(ref) => (this.uploadList = ref)}
                      onChange={this.onClickHandler}
                      style={{ display: "none" }}
                      accept=".xlsx, .csv, .xls"
                          />
                          {ApiRequest.can_access("super-admin", "can_edit") && (
                          <button
                      type="button"
                      className="btn btn-sm btn-primary"
                      onClick={(e) => this.uploadList.click()}
                    >
                      {"Bulk Station User Mobile Change"}
                    </button>
                    )}
                    {" "}
                    <a
                      class="btn btn-sm btn-primary mr-5"
                      href={"/admin_user_form/"}
                    >
                      {" "}
                      Add New
                      {"    "}
                    </a>
                  </div>
                </div>
              </div>
              <div className="box-body">
                <div>
                  <div className="tableHeader">
                    <div className="showEntries">
                      Show{" "}
                      <select
                        value={limit}
                        className="form-control input-sm mr-5 ml-5"
                        onChange={this.handleLimitChange}
                      >
                        <option value={10}>10</option>
                        <option value={25}>25</option>
                        <option value={50}>50</option>
                        <option value={100}>100</option>
                      </select>{" "}
                      entries
                    </div>
                    {/*
                      <div className="cusSearch">
                        Search{" "}
                        <select
                          onChange={this.handleSearchChange}
                          value={search_select_value}
                          className="form-control input-sm mr-5 ml-5"
                        >
                          <option value="">Select Search by </option>
                          <option value={"id"}>ID</option>
                          <option value={"store_name"}>Store Name</option>
                          <option value={"store_code"}>Store Code</option>
                          <option value={"city"}>City</option>
                        </select>
                        <input
                          type="search"
                          class="form-control input-sm mr-5 ml-5"
                          value={search_input_value}
                          onChange={this.handleSearchValueChange}
                          placeholder=""
                        />
                        <button
                          className="btn btn-primary cusSearchBtn"
                          onClick={this.handleSearchClear}
                        >
                          Clear
                        </button>
                      </div>
                    */}
                  </div>
                  <div className="table-responsive">
                    {loading ? (
                      <LoadingSpinner />
                    ) : (
                      <table className="table no-margin">
                        <thead>
                          <tr>
                            <th>ID</th>
                            <th>Name</th>
                            <th>Email Address</th>
                            <th>Role</th>
                            <th>Status</th>
                            <th>Created At</th>
                            <th>Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {user_list.length > 0 ? (
                            user_list.map((data) => {
                              return (
                                <tr key={data.id}>
                                  <td>{this.rescue_value(data.id)}</td>
                                  <td>{this.rescue_value(data.name)}</td>
                                  <td>{this.rescue_value(data.email)}</td>
                                  <td>{this.rescue_value(data.role)}</td>
                                  <td>{this.rescue_status(data.status)}</td>
                                  <td>
                                    {ApiRequest.date_format_utc(data.createdAt)}
                                  </td>
                                  <td>
                                    {ApiRequest.can_access(
                                      "admin-user",
                                      "can_view_single"
                                    ) && (
                                      <a
                                        class="btn btn-primary btn-xs mr-5"
                                        href={"/admin_user_view/" + data.id}
                                      >
                                        {" "}
                                        <i class="fa fa-eye" />
                                        {"    "}
                                      </a>
                                    )}
                                    {ApiRequest.can_access(
                                      "admin-user",
                                      "can_edit"
                                    ) && (
                                      <a
                                        class="btn btn-primary btn-xs mr-5"
                                        href={"/admin_user_edit/" + data.id}
                                      >
                                        {" "}
                                        <i class="fa fa-edit" />
                                        {"    "}
                                      </a>
                                    )}
                                    {localStorage.getItem("user_id") !==
                                      data.id && (
                                      <a
                                        class="btn btn-primary btn-xs mr-5"
                                        href={"/user_delete/" + data.id}
                                      >
                                        <i class="fa fa-trash" />
                                      </a>
                                    )}
                                  </td>
                                </tr>
                              );
                            })
                          ) : (
                            <td colSpan="5" className="noRecord">
                              No Record Found
                            </td>
                          )}
                        </tbody>
                      </table>
                    )}
                  </div>
                  {pagination.size > 0 && (
                    <div className="tableFooter">
                      <div>
                        <div class="dataTables_info">
                          Showing {(pagination.currentPage - 1) * limit + 1} to{" "}
                          {pagination.currentPage * limit} of {pagination.size}{" "}
                          entries
                        </div>
                      </div>
                      <div style={{ textAlign: "right" }}>
                        <Pagination
                          prevPageText={"<"}
                          nextPageText={">"}
                          activePage={pagination.currentPage}
                          itemsCountPerPage={pagination.limit}
                          totalItemsCount={pagination.size}
                          pageRangeDisplayed={5}
                          onChange={this.handlePageChange}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
              {/*<div className="box-body">
                {loading ? (
                  <LoadingSpinner />
                ) : (
                  <DataTable
                    title="News Integration"
                    selectableRows={false}
                    records={records}
                    columns={columns}
                    highlightOnHover={false}
                    pointerOnHover={false}
                    pagination={false}
                    striped={true}
                    data={this.state.user_list}
                    paginationPerPage={3}
                    onClickEvents={{
                      clickable: (data, rowIdx, rowData) => {
                        this.props.handleClick(data, "edit");
                      }
                    }}
                    id="externally-controlled"
                    options={{}}
                  />
                )}
                  </div>*/}
            </div>
          </Col>
        </Row>
      </Content>
    );
  }
}

import React from "react";
import { Content } from "adminlte-2-react";
//import axios from "axios";
import API from "../../constants";
import ApiRequest from "../../ApiRequest";
import LoadingAjax from "../../components/Loading";
//import LoadingSpinner from "../../components/LoadingSpinner";

const user_form_url = API.apiEndpoint + API.adminUser;
const role_url = API.apiEndpoint + API.role;

export default class AdminEditForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page: {},
      action: "add",
      name: "",
      email: "",
      password: "",
      status: "",
      partner_code: "",
      user_role_list: [],
      role: "",
      error: false,
      message: "",
      loading: true,
      success: false,
      success_message: "",
    };

    this.handleChange = this.handleChange.bind(this);
    //this.changePass = this.changePass.bind(this);
  }

  async componentDidMount() {
    let url = user_form_url + "/" + this.props.match.params.id;
    let options = { url: url };
    let api_response = await ApiRequest.get(options);
    if (api_response.error) {
      this.setState({
        loading: false,
        error: true,
        message: api_response.message,
      });
    } else {
      let data = api_response.data;

      this.setState({
        //user_role_list: api_response.data,
        name: data.name,
        email: data.email,
        //password: data.password,
        role: data.role,
        partner_code: data.partner_code,
        status: data.status,
        loading: false,
        error: false,
        message: api_response.message,
      });
    }

    let roleUrl = role_url;
    let optionsRole = { url: roleUrl };
    let api_response_role = await ApiRequest.get(optionsRole);
    if (api_response_role.error) {
      this.setState({
        loading: false,
        error: true,
        message: api_response_role.message,
      });
    } else {
      this.setState({
        user_role_list: api_response_role.data,
        loading: false,
        error: false,
        message: api_response_role.message,
      });
    }

    // Request Roles Data  drop-down

    // if (api_response.error) {
    //   this.setState({
    //     loading: false,
    //     error: true,
    //     message: api_response.message
    //   });
    // } else {
    //   this.setState({
    //     user_role_list: api_response.data,
    //     loading: false,
    //     error: false,
    //     message: api_response.message
    //   });
    // }
  }

  handleChange(event) {
    this.setState({ value: event.target.value });
  }

  nameChange = (event) => {
    this.setState({ name: event.target.value });
  };

  partnerCodeChange = (event) => {
    this.setState({ partner_code: event.target.value });
  };
  emailChange = (event) => {
    this.setState({ email: event.target.value });
  };

  // passChange = event => {
  //   this.setState({ password: event.target.value });
  // };

  roleChange = (event) => {
    this.setState({ role: event.target.value });
  };

  statusChange = (event) => {
    this.setState({ status: event.target.value });
  };

  saveUser = async (event) => {
    const formData = new FormData();
    formData.append("name", this.state.name);
    formData.append("email", this.state.email);
    //formData.append("password", this.state.password);
    formData.append("status", this.state.status);
    formData.append("role", this.state.role);
    formData.append("partner_code", this.state.partner_code);

    let api_response = {};
    let options = { url: user_form_url, form_data: formData };

    options.url = user_form_url + "/" + this.props.match.params.id;
    api_response = await ApiRequest.put(options);

    if (api_response.error) {
      this.setState({
        loading: false,
        error: true,
        message: api_response.message,
      });
    } else {
      this.setState({
        loading: false,
        success: true,
        success_message: api_response.message,
      });
      setTimeout(
        function () {
          this.setState({ success: false });
          window.location = "/admin_user";
        }.bind(this),
        10000
      );
    }
  };

  backUser = () => {
    window.location = "/admin_user";
  };

  // changePass = () => {
  //   const rand = Math.floor(10000000 + Math.random() * 9000000000000);
  //   this.setState({ password: rand });
  // };

  render() {
    const { user_role_list } = this.state;
    let { error, message, success, success_message } = this.state;
    return (
      <Content title="Admin User" browserTitle="Admin User">
        <LoadingAjax />
        <div className="row">
          {error && (
            <div
              class="alert alert-danger alert-dismissible"
              style={{ marginRight: "15px", marginLeft: "15px" }}
            >
              <button
                type="button"
                class="close"
                data-dismiss="alert"
                aria-hidden="true"
              >
                ×
              </button>
              {message}
            </div>
          )}
          {success && (
            <div
              class="alert alert-success alert-dismissible"
              style={{ marginRight: "15px", marginLeft: "15px" }}
            >
              <button
                type="button"
                class="close"
                data-dismiss="alert"
                aria-hidden="true"
              >
                ×
              </button>
              {success_message}
            </div>
          )}
          <div className="col-md-12">
            <div className="box box-primary">
              <div className="box-header with-border">
                <h3 className="box-title">Admin User Form </h3>
              </div>

              <div className="box-body">
                <div className="form-group">
                  <label htmlFor="name">Name</label>
                  <input
                    type="text"
                    className="form-control"
                    id="name"
                    placeholder="Name"
                    value={this.state.name}
                    onChange={this.nameChange}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="email">Email</label>
                  <input
                    type="text"
                    className="form-control"
                    id="email"
                    placeholder="Email address"
                    value={this.state.email}
                    onChange={this.emailChange}
                  />
                </div>
                {/* <div className="form-group">
                  <label htmlFor="password">Password</label>
                  <input
                    type="text"
                    className="form-control"
                    id="password"
                    placeholder="Password"
                    value={this.state.password}
                    onChange={this.passChange}
                  />
                  <small className="pull-right">
                    <a href="JavaScript:Void(0);" onClick={this.changePass}>
                      Generate Password
                    </a>
                  </small>
                </div>*/}
                <div className="form-group">
                  <label htmlFor="role">Role</label>
                  <select
                    className="form-control"
                    id="role"
                    value={this.state.role}
                    onChange={this.roleChange}
                  >
                    {user_role_list &&
                      user_role_list.map((e, i) => {
                        return <option value={e.alias}>{e.name}</option>;
                      })}
                  </select>
                </div>
                <div className="form-group">
                  <label htmlFor="status">Status</label>
                  <select
                    className="form-control"
                    value={this.state.status}
                    onChange={this.statusChange}
                  >
                    <option value="1">Active</option>
                    <option value="2">Inactive</option>
                  </select>
                </div>
                <div className="form-group">
                  <label htmlFor="partner_code">Partner Code (optional)</label>
                  <input
                  type="text"
                  className="form-control"
                  id="name"
                  placeholder="Partner Code"
                  value={this.state.partner_code}
                  onChange={this.partnerCodeChange}
                  />
                </div>
              </div>
              <div className="box-footer clearfix no-border">
                <button
                  onClick={this.saveUser}
                  className="btn btn-primary pull-right "
                >
                  Submit
                </button>
                <button
                  onClick={this.backUser}
                  className="btn btn-default pull-right mr-10"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </Content>
    );
  }
}

import React, { Component } from "react";

import { Content, Row, Col } from "adminlte-2-react";
import Pagination from "react-js-pagination";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

import LoadingAjax from "../../components/Loading";
import LoadingSpinner from "../../components/LoadingSpinner";
import API from "../../constants";
import ApiRequest from "../../ApiRequest";
require("bootstrap/less/bootstrap.less");

const store_url = API.apiEndpoint + API.store;
const reconciliation_trx = API.apiEndpoint + API.reconciliation_trx_url;

export default class ReconciliationViews extends Component {
	state = {
		data: [],
		status: "",
		discrepancy: "",
		stats: [],
		activePage: 1,
		error: false,
		success: false,
		message: "",
		success_message: "",
		loading: false,
		limit: 20,
		callApi: false,
		enableBtn: false,
		pagination: {},
	};

	async componentDidMount() {
		const options = { url: store_url };
		const optionsRewards = { url: reconciliation_trx };

		const api_response = await ApiRequest.get(options);
		const response = await ApiRequest.get(optionsRewards);

		if (api_response.error) {
			this.setState({
				loading: false,
				error: true,
				message: api_response.message,
			});
		} else {
			this.setState({
				loading: false,
				error: false,
				message: api_response.message,
			});
			if (this.callApi) {
				await ApiRequest.get(options);
				const { reconciliationTransactions, pagination, stats } =
					response.data;
				this.setState({
					data: reconciliationTransactions,
					stats,
					pagination,
				});
			}
		}
	}

	async componentDidMount() {
		await this.getReportData();
	}

	async getReportData(pageNumber = 1, paginationType = null) {
		this.setState({ loading: true });
		let url =
			reconciliation_trx +
			"/" +
			this.props.match.params.id +
			"?page=" +
			pageNumber;

		if (this.state.date_from) {
			url = url + "&date_from=" + this.state.date_from;
		}
		if (this.state.date_to) {
			url = url + "&date_to=" + this.state.date_to;
		}
		if (this.state.status) {
			url = url + "&status=" + this.state.status;
		}
		if (this.state.discrepancy) {
			url = url + "&discrepancy=" + this.state.discrepancy;
		}

		const options = { url: url };
		const response = await ApiRequest.get(options);

		if (response.error) {
			this.setState({
				loading: false,
				error: true,
				message: response.message,
			});
		} else {
			const { reconciliationTransactions, pagination, stats } =
				response.data;
			this.setState({
				loading: false,
				error: false,
				callApi: true,
				data: reconciliationTransactions,
				stats,
				pagination:
					paginationType == null || paginationType === "getReport"
						? pagination
						: this.state.pagination,
			});
		}
	}

	handleSubmit = async () => {
		await this.getReportData();
	};

	handlePageChange = async (pageNumber, paginationType) => {
		await this.getReportData(pageNumber, paginationType);
	};

	handleChangeStatus = (event) => {
		this.setState({
			status: event.target.value,
			enableBtn: true,
			// pagination: {},
			limit: 20,
		});
	};

	handleChangeDiscrepancy = (event) => {
		this.setState({
			discrepancy: event.target.value,
			enableBtn: true,
			// pagination: {},
			limit: 20,
		});
	};

	noResultText() {
		return "N/A";
	}

	// todo: implement export
	async downloadData() {
		console.log("Not Implemented!!");
	}

	render() {
		const {
			data,
			loading,
			error,
			message,
			success,
			success_message,
			callApi,
			pagination,
			limit,
		} = this.state;
		return (
			<Content
				title="Reconciliation Transactions"
				browserTitle="Reconciliation Transaction"
			>
				<LoadingAjax />
				<Row>
					{error ? <ErrorAlert message={message} /> : null}
					{success ? (
						<SuccessAlert success_message={success_message} />
					) : null}
					<Col xs={12}>
						<div className="box box-primary">
							<ExportButton />
							<FilterForm
								handleChangeStatus={this.handleChangeStatus}
								handleChangeDiscrepancy={
									this.handleChangeDiscrepancy
								}
								handleSubmit={this.handleSubmit}
							/>
							<Statistics
								loading={loading}
								noResultText={this.noResultText}
								stats={this.state.stats}
							/>
							<Table
								handlePageChange={this.handlePageChange}
								pagination={pagination}
								loading={loading}
								limit={limit}
								callApi={callApi}
								data={data}
							/>
						</div>
					</Col>
				</Row>
			</Content>
		);
	}
}

class ErrorAlert extends Component {
	render() {
		return (
			<div
				className="alert alert-danger alert-dismissible"
				style={{ marginRight: "15px", marginLeft: "15px" }}
			>
				<button
					type="button"
					className="close"
					data-dismiss="alert"
					aria-hidden="true"
				>
					×
				</button>
				{this.props.message}
			</div>
		);
	}
}

class SuccessAlert extends Component {
	render() {
		return (
			<div
				className="alert alert-success alert-dismissible"
				style={{ marginRight: "15px", marginLeft: "15px" }}
			>
				<button
					type="button"
					className="close"
					data-dismiss="alert"
					aria-hidden="true"
				>
					×
				</button>
				{this.props.success_message}
			</div>
		);
	}
}

class ExportButton extends Component {
	render() {
		return (
			<div className="box-header with-border box-table-cus">
				<h3 className="box-title">Report</h3>
				<div className="box-tools">
					<button
						className="btn btn-sm btn-primary"
						disabled={true}
						onClick={this.props.downloadData}
					>
						Export
					</button>
				</div>
			</div>
		);
	}
}

class FilterForm extends Component {
	render() {
		return (
			<div
				className="row clearfix"
				style={{ marginTop: "5px", padding: "10px" }}
			>
				<div className="col-xs-4">
					<div className="form-group">
						<label>Status</label>
						<select
							className="form-control"
							value={this.props.status}
							onChange={this.props.handleChangeStatus}
						>
							<option value="">Select Status</option>
							<option value="completed">Completed</option>
							<option value="uncompleted">Uncompleted</option>
						</select>
					</div>
				</div>
				<div className="col-xs-4">
					<div className="form-group">
						<label>Discrepancy</label>
						<select
							className="form-control"
							value={this.props.discrepancy}
							onChange={this.props.handleChangeDiscrepancy}
						>
							<option value="">Select Discrepancy</option>
							<option value="status-mismatch">
								Status Mismatch
							</option>
							<option value="amount-mismatch">
								Amount Mismatch
							</option>
							<option value="status-amount-mismatch">
								Status & Amount Mismatch
							</option>
							<option value="conflict">Not Found</option>
						</select>
					</div>
				</div>
				<div className="text-right col-xs-12">
					<button
						onClick={this.props.handleSubmit}
						className="btn btn-primary"
					>
						Submit
					</button>
				</div>
			</div>
		);
	}
}

class Statistics extends Component {
	listOfCards = [
		{
			title: "Uncompleted Transactions",
			value: 0,
			key: "uncompleted",
			content: "Transactions that were initiated but not completed",
		},
		{
			title: "Completed Transactions",
			value: 0,
			key: "completed",
			content: "Transactions that were successfully completed",
		},
		{
			title: "Gateway Records Not Found",
			value: 0,
			key: "conflict",
			content:
				"Transactions from the gateway that are missing in our records",
		},
		{
			title: "Amount & Status Mismatch Transactions",
			value: 0,
			key: "status-amount-mismatch",
			content: "Transactions with discrepancies in amount and status",
		},
		{
			title: "Amount Mismatch Transactions",
			value: 0,
			key: "amount-mismatch",
			content: "Transactions where the amounts do not match",
		},
		{
			title: "Status Mismatch Transactions",
			value: 0,
			key: "status-mismatch",
			content: "Transactions where the statuses do not match",
		},
	];
	getStats = () => {
		const statCards = this.getStatCards();
		return this.listOfCards.map((card) => {
			const foundStat = statCards.find((stat) => {
				return stat.key === card.key;
			});
			if (foundStat) {
				return {
					...card,
					value: foundStat.value,
				};
			}
			return card;
		});
	};
	getStatCards = () => {
		return this.props.stats.map((item) => {
			const keys = Object.keys(item).filter((key) => key !== "count");
			return {
				key: item[keys[0]],
				value: item.count,
			};
		});
	};
	handel = () => {};

	render() {
		return (
			<div className="box-body">
				<div className="row">
					{this.getStats().map((item) => (
						<div key={item.title} className="col-md-4 col-sm-6">
							<div className="info-box-custom">
								<div className="info-box-content-custom">
									<h4 className="info-box-text-custom">
										<OverlayTrigger
											placement="bottom"
											overlay={
												<Tooltip id="button-tooltip-2">
													{item.content}
												</Tooltip>
											}
										>
											<i
												className="fa fa-solid fa-info-circle fa-2xs"
												style={{ marginRight: "4px" }}
											></i>
										</OverlayTrigger>
										{item.title}
									</h4>
									<span className="info-box-number-custom">
										{this.props.loading ? (
											<LoadingSpinner />
										) : (
											<div>
												<span className="info-box-number-custom">
													{this.props.loading ? (
														<LoadingSpinner />
													) : item.value ? (
														<div>
															<span className="info-box-number-custom">
																{item.value
																	.toString()
																	.replace(
																		/\B(?=(\d{3})+(?!\d))/g,
																		","
																	)}
															</span>
														</div>
													) : (
														<div>
															{this.props.noResultText()}
														</div>
													)}
												</span>
											</div>
										)}
									</span>
								</div>
							</div>
						</div>
					))}
				</div>
			</div>
		);
	}
}

class Table extends Component {
	rescue_value(val) {
		return val === undefined || val === null || val.length <= 0 ? "-" : val;
	}
	renderStatus(status) {
		if (status === "uncompleted" || status === "") {
			return (
				<span
					style={{
						background: "rgba(255,191,0,0.3)",
						padding: "5px 15px",
						borderRadius: "9999px",
						fontWeight: "600",
						textTransform: "capitalize",
					}}
				>
					<i
						className="fa fa-solid fa-flag fa-2xs"
						// style={{ marginRight: "4px" }}
					></i>
				</span>
			);
		} else if (status === "completed" || status === "Success") {
			return (
				<span
					style={{
						background: "rgba(0,255,0,0.3)",
						padding: "5px 15px",
						borderRadius: "9999px",
						fontWeight: "600",
						textTransform: "capitalize",
					}}
				>
					<i
						className="fa fa-solid fa-flag fa-2xs"
						// style={{ marginRight: "4px" }}
					></i>
				</span>
			);
		} else if (status === "failed" || status === "Failed") {
			return (
				<span
					style={{
						background: "rgba(255,0,0,0.3)",
						padding: "5px 15px",
						borderRadius: "9999px",
						fontWeight: "600",
						textTransform: "capitalize",
					}}
				>
					<i
						className="fa fa-solid fa-flag fa-2xs"
						// style={{ marginRight: "4px" }}
					></i>
				</span>
			);
		}
	}
	render() {
		return (
			<div className="box-body">
				<div className="table-responsive">
					{this.props.loading ? (
						<LoadingSpinner />
					) : (
						<div>
							<table
								className="table no-margin"
								id="export-table-inventory"
							>
								<thead>
									<tr>
										<th>Id</th>
										<th>Status</th>
										<th>Thawani Reference</th>
										<th>Card Number</th>
										<th>Amount</th>
										<th>OOMCO Status</th>
										<th>Thawani Status</th>
										{/* <th>RPS Status</th> */}
										<th>Date</th>
										<th>Time</th>
										<th>Action</th>
									</tr>
								</thead>
								<tbody>
									{this.props.data &&
									this.props.data.length > 0 ? (
										this.props.data.map((data) => {
											return (
												<tr key={data.id}>
													<td>
														{this.rescue_value(
															data.id
														)}
													</td>
													<td>
														<td>
															{this.renderStatus(
																data.status
															)}
														</td>
													</td>
													<td>
														{this.rescue_value(
															data.thawaniTransaction_id
														)}
													</td>
													<td>
														{this.rescue_value(
															data
																.thawaniTransaction
																?.cardNumber
														)}
													</td>
													<td>
														{this.rescue_value(
															data.external.Amount
														)}
													</td>
													<td>
														{this.renderStatus(
															data.OOMCOStatus
														)}
													</td>
													<td>
														{this.renderStatus(
															data.thawaniStatus
														)}
													</td>
													{/* <td>
														{this.renderStatus(
															data.RPSStatus
														)}
													</td> */}
													<td>
														{ApiRequest.date_format(
															data.createdAt
														)}
													</td>
													<td>
														{ApiRequest.time_format(
															data.createdAt
														)}
													</td>
													<td>
														<a
															class="btn btn-primary btn-xs"
															href={
																"/reports/reconciliation_transaction_details/" +
																data.id
															}
														>
															{" "}
															<i class="fa fa-eye" />{" "}
														</a>
													</td>
												</tr>
											);
										})
									) : (
										<td colSpan="10" className="noRecord">
											{this.props.callApi
												? "No Record Found"
												: "Please Select Filter"}
										</td>
									)}
								</tbody>
							</table>
							{this.props.pagination.size > 0 && (
								<div className="tableFooter">
									<div>
										<div className="dataTables_info">
											Showing{" "}
											{(this.props.pagination
												.currentPage -
												1) *
												this.props.limit +
												1}{" "}
											to{" "}
											{this.props.pagination.currentPage *
												this.props.limit}{" "}
											of {this.props.pagination.size}{" "}
											entries
										</div>
									</div>
									<div style={{ textAlign: "right" }}>
										<Pagination
											prevPageText={"<"}
											nextPageText={">"}
											activePage={
												this.props.pagination
													.currentPage
											}
											itemsCountPerPage={
												this.props.pagination.limit
											}
											totalItemsCount={
												this.props.pagination.size
											}
											pageRangeDisplayed={5}
											onChange={
												this.props.handlePageChange
											}
										/>
									</div>
								</div>
							)}
						</div>
					)}
				</div>
			</div>
		);
	}
}
